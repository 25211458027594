import { Icon } from '@loveholidays/design-system';
import React, { useRef } from 'react';
import { SxStyleProp } from 'theme-ui';

import { useDetailsAnimation } from './useDetailsAnimation';
import { ClassNameProps } from '@ComponentProps';

export interface AccordionProps extends React.PropsWithChildren<ClassNameProps> {
  accordionHeader: React.ReactElement;
  accordionHeaderStyle?: SxStyleProp;
  accordionFooter?: React.ReactElement;
  accordionFooterStyle?: SxStyleProp;
  collapsedByDefault?: boolean;
  contentStyles?: SxStyleProp;
  onClickTracking?: () => void;
}

export const Accordion: React.FC<AccordionProps> = ({
  accordionHeader,
  accordionHeaderStyle,
  accordionFooter,
  accordionFooterStyle,
  className,
  'data-id': dataId,
  children,
  collapsedByDefault = false,
  contentStyles,
  onClickTracking,
}) => {
  const outerContentRef = useRef<HTMLDivElement>(null);
  const innerContentRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<SVGSVGElement>(null);
  const detailsRef = useRef<HTMLDetailsElement>(null);
  const { onClick } = useDetailsAnimation({
    collapsedByDefault,
    outerContentRef,
    innerContentRef,
    detailsRef,
    iconRef,
  });

  return (
    <details
      ref={detailsRef}
      open={!collapsedByDefault}
      className={className}
      data-id={dataId}
    >
      <summary
        sx={{
          cursor: 'pointer',
          listStyle: 'none',
          userSelect: 'none',
          '&::-webkit-details-marker': {
            display: 'none',
          },
        }}
        onClick={(e) => {
          onClick(e);
          onClickTracking?.();
        }}
      >
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            ...accordionHeaderStyle,
          }}
        >
          {accordionHeader}

          <Icon
            data-id="expand-on"
            name="Toggles/ExpandOn"
            size="20"
            ref={iconRef}
            sx={{
              display: 'inline',
              transitionProperty: 'transform',
              transform: collapsedByDefault ? null : 'rotate(180deg)',
              transitionDuration: (t) => `${t.motion.moveKangaroo.duration}ms`,
              transitionTimingFunction: (t) => t.motion.moveKangaroo.easing,
            }}
          />
        </div>
      </summary>

      <div
        ref={outerContentRef}
        sx={{
          overflow: 'hidden',
          transitionProperty: 'height',
          height: collapsedByDefault ? 0 : null,
          transitionDuration: (t) => `${t.motion.moveKangaroo.duration}ms`,
          transitionTimingFunction: (t) => t.motion.moveKangaroo.easing,
        }}
      >
        <div
          ref={innerContentRef}
          sx={contentStyles}
        >
          {children}
        </div>
        {accordionFooter && (
          <div
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              ...accordionFooterStyle,
            }}
          >
            {accordionFooter}
          </div>
        )}
      </div>
    </details>
  );
};
